import moment from 'moment';
function padTo2Digits(num) {
  return String(num).padStart(2, '0');
}
const formatDateTime = function(date) {
  if (!date) return null;
  let dDate = new Date(date);
  let formatedDate = padTo2Digits(dDate.getFullYear()) + '-' + padTo2Digits(dDate.getMonth()+1) + '-' + padTo2Digits(dDate.getDate());
  formatedDate += ' à '+padTo2Digits(dDate.getHours()) + ':' + padTo2Digits(dDate.getMinutes());
  return formatedDate;
};

export { formatDateTime };
